export const skinsType = {
  0: {
    限定: [
      "9f51da57-4623-415a-6313-f49588297d0e",
      "470e2855-4b86-f62d-258f-4588056a7b23",
      "524b0c42-47da-e7ca-dc04-85ba285b2e71",
      "ba0abff4-41e4-4036-980d-90a55837b692",
      "69f94fda-4603-744a-87fe-2391be7462e8",
      "19c97db0-4ea8-d0de-d2a9-398eecb07298",
      "b37b99d4-4bbe-98c6-0168-3dafda0768e9",
      "3f547576-41a5-9b71-76d5-9288bb031350",
      "e98461ab-4e29-3bbe-513b-0b82f3013050",
      "445de3d7-4833-5bf7-ef75-aeb4a0212229",
      "3bc38af9-4068-164e-d42f-da844a259058",
      "00c41304-4708-9e2f-e05a-4a8dbf2e5c28",
    ],
    近战: [
      "5ac106cd-45ef-a26f-2058-f382f20c64db",
      "8e9f610a-41ea-ff48-d102-c7a0e5614c84",
      "e98461ab-4e29-3bbe-513b-0b82f3013050",
      "35792a19-4e62-f3bc-f5df-289599d6cafc",
      "99b0edce-48db-b898-1d6f-0fa89795226d",
      "445de3d7-4833-5bf7-ef75-aeb4a0212229",
      "062e684d-4212-eeff-7443-cbb53b0f5ce2",
      "901abab9-4a44-09f1-b892-9887a587f079",

      "a2a5860a-4a34-8510-e067-93abc9f18e12",
      "a1762ed3-45bf-2dd5-776f-a18a33171e6f",
      "424ed674-47dd-0488-de93-6cb8e13d0b90",
      "9f09d408-4e55-b9bb-580a-9fa37e3730b5",
      "2861ed64-4dd9-b0c6-a10d-ee98527924eb",
      "7f980c1c-4a80-21e8-bf3e-77bf4c5f1d4b",
      "6e37a33a-416e-fcc0-ceb8-7784e18fbfe9",
      "ec70c8c8-48db-b2dc-ab7d-1d9410b0cfbf",
      "4351b2ed-42a1-4d84-804b-2f8d695e5069",
      "85db497e-4b7d-dd9d-bc09-6d926158ed74",
      "f2090053-4392-37bf-18bc-e7a03c1ebd82",
      "f353f65b-4764-cfc5-df01-8db85ab28199",
      "11c1c7fb-4fe7-1f6f-e4c4-47a9f46f7ea2",
      "b237dca6-4059-8a73-c279-0187207c34c8",
      "f58e7da3-4567-707f-cb1e-3a9add422481",
      "3f5b498c-4ca1-b441-4433-a186e4ae2054",
      "54aa7ea7-4839-65b3-e0a8-d6a6aeb945fd",
      "d08e13ff-4252-3be0-9c13-33aba0ed670d",
      "25cf8e2b-49af-5c9d-c0c7-7780f268a057",
      "470e2855-4b86-f62d-258f-4588056a7b23",
      "ba0abff4-41e4-4036-980d-90a55837b692",
      "19c97db0-4ea8-d0de-d2a9-398eecb07298",
      "3f547576-41a5-9b71-76d5-9288bb031350",
      "5f059d7e-413c-445c-e36f-9a9791fba5b0",
      "21d49b4a-49ce-79f9-4e08-67959f8d17b5",
      "daeef818-4c77-4a1a-97e8-feb3401ba8a3",
      "30de237a-4fb3-90f5-3630-65be64d01423",
      "88d7c503-477f-c881-af2c-53bbab55807c",
      "50bf2a27-411a-6402-d032-309247ad972e",
      "4875e120-4d7d-aa2a-71c5-c0851c4af00d",
      "c6c10bfd-45e1-1295-c32e-2b8ac31db5ec",
      "48ce4a70-4207-623b-4739-bfb937812432",
      "f27623a6-40de-20b9-17b5-c5bed27453a7",
      "96fe45cd-448d-890f-f3f6-86b25516c1a0",
      "088c0da1-4130-0ebe-ac0c-30a237a0df10",
      "901425cd-405a-d189-3516-ba954965e559",
      "d6b75f1b-409b-bae9-d378-d3adf04a3ca9",
      "f3594bcc-43a9-4a74-8c40-98a4e4a4569a",
      "1d7449fe-4bba-e213-c0c2-39816ca1043f",
      "ea441610-42da-e46f-8d7b-1b9759c105cd",
      "698dc2f6-4ba9-9409-4737-05afdc622a1c",
      "ed0080a9-4d77-d279-5a5c-f2bdb630307d",
      "e701b267-43bb-7862-cebb-9695590b68ac",
      "582533ed-43cb-67da-7c1e-0999304cc7cf",
      "1dc43c70-4dec-a8e6-e9fa-cda0033cc041",
      "63991095-4963-33ba-339f-50b69eb88fe1",
      "5fecfd43-4bec-4e6a-842c-84860853df82",
      "c01062ab-48ed-11a2-46bb-dba096daca59",
      "726b77c9-41c3-80c6-f33e-068b9f74ef56",
      "9d9ec56d-4973-3531-64f5-6d8eb2beb0a2",
      "895715e4-4111-a737-8cb7-0ab420659bc9",
      "656c5de8-47ca-4eeb-6051-c9aabbf37baa",
      "3c88b5b5-4e7b-74e7-1964-488246de61b7",
      "249b0e46-4a11-f045-51bb-649151cd802a",
      "167481de-4732-b44a-06ad-938625015abe",
      "215a7da6-4903-097a-5d3d-87803bee082e",
      "3a1ec58c-4380-0622-7fde-c1936519fcf2",
      "a7b3b9b4-4bc5-ce73-e046-bdac7d1e9d71",
      "49f20fc3-483e-6343-7981-e6b086eeac57",
      "76722e7d-4a64-a76e-98a7-0b9ec7d107e2",
      "7cf3af0b-4556-a0db-2e04-eea4368b354b",
      "56f387f3-4b88-46b0-ee0d-249145f05d26",
      "e94012b1-4a22-63d2-117e-90a2c1375ded",
      "8c2e0f93-4277-fbb8-20c6-e2b2638b6ed4",
      "a17e9fcc-4081-9b5a-cf63-fca395e652df",
      "ef67d6cb-4f7f-28ce-2973-cf90a97ae54d",
      "9aa3eb5c-4487-c36a-a6be-b7918e08244e",
      "b6768f01-4d72-f0f1-65b5-71b751536490",
      "606560ae-4ccf-767a-0f2f-0599d85abaee",
      "dc5e3cfc-466a-4819-e93b-2ba5f1847915",
      "9464eca6-4ba2-adaa-a57a-d78eb0b38696",
      "7302d465-46cd-2a43-655f-93aee59030db",
      "e57317ac-4a93-50a9-30e9-93a098513fa9",
      "00c41304-4708-9e2f-e05a-4a8dbf2e5c28",
      "c1326ee3-47af-d823-6686-618e1bcdf0c6",
      "c5ec6a47-40c2-66ed-d546-5bad1eef6fd5",
      "d6e93360-46a2-0103-afa0-8b91e78b6fe8",
      "dd587e30-4932-6cd4-3437-a1a043dd6a78",
      "2521e64e-4395-5fc0-d53b-0297f47f1294",
      "5a27fbea-4f1d-62f9-8e5f-f494b4f0d322",
      "a080b538-4593-b092-d29c-04b80e6e983f",
      "21ceb4b1-480f-dddb-1422-8aad73519181",
      "a45e899f-459d-469b-dc8f-8da5c0546bf0",
      "e34039b6-441d-3e17-2773-bdaf5c3d2faa",
      "2825e79c-43bc-cb08-a89c-c9b095502e48",
      "5629ead8-442c-9a31-9622-cd9bf93aec4b",
      "704a71ab-4b0e-6e03-466e-5b9ca9d987fd",
      "47897cd1-49e2-da3f-4a8f-b6a1e84b0dd0",
      "278e57d8-4abd-57b9-394b-abb8b48c1cd3",
      "3710f62c-4e0c-65fd-848d-8da25d2fb833",
      "dcb0b818-4db1-c3f8-6055-d1ab2a0ae55a",
      "315e8e49-4dc4-9769-2382-3ba5f7af87da",
      "97899f63-4b47-a315-7ef4-f39b6d46bb37",
      "eb555be2-4207-7f90-f68d-a2911e665a52",
      "45159daa-423b-200a-c043-babdec9d0fd8",
      "5db07bae-4b88-1589-1c19-9f987da28c1b",
      "155ba654-4afa-1029-9e71-e0b6962d5410",
      "d7a02a43-47a5-556c-a69f-ec9cf6ede66d",
      "12dce8f5-42fe-87c6-ef37-8aa4e55f1f64",
      "90fe45d6-41ea-1c49-8fb9-46b0e98c0077",
      "87ec235c-468b-54ac-66ec-249cedbbc185",
      "e85cf8b6-467b-bc6c-9013-959383f71b2e",
      "4745ba48-4d62-b01c-9cb9-48848fec9b66",
      "52f83cac-4d68-fd67-5c60-5986139a638d",
      "a8485a93-48fa-a301-a3f2-dca0175580df",
      "f97170fe-4bae-bf84-5488-fdb4c3f4d169",
      "1521c8d1-4071-f91d-1d22-c4aa30590fe4",
      "a7a78903-4ffd-3db7-33af-8f8bc8857450",
      "d3a228df-4eb5-09c4-0e32-93b50c9ec842",
      "6359a802-440e-b775-4ba4-fa88d3bddb07",
      "5471ca4a-4f45-a415-2c42-9b8aa9848048",
      "708abbc6-4579-4452-4293-07ba45e78979",
      "b065e536-464f-e21f-9a9e-4fa46d33e8ba",
      "5bf17117-4ead-a0a8-2961-288d38985e93",
      "9757d458-4062-5132-693c-868e52530a6e",
      "b577585f-4171-19a1-2899-848460de8089",
    ],
  },
  1: {
    暴徒: [
      "b3d3ff38-4202-20d8-2f41-c783477e5636",
      "a2036653-4cc2-b7cd-7e08-1aa2f510d9ad",
      "4e435234-49a2-1444-4640-908692c855b8",
      "a3dba920-44ee-d7c5-5227-99a80aee3bd9",
      "9f51da57-4623-415a-6313-f49588297d0e",
      "69f94fda-4603-744a-87fe-2391be7462e8",
      "7e44fc1b-44fa-cdda-8491-f8a5bca1cfa3",
      "59d61899-4573-65ea-0e42-2ea6d324d32b",
      "1010fb40-4344-6ec8-2a8a-33bf076339b6",
      "43e7b969-4d2b-6d7b-6430-81bb37767481",
      "56c5c2cf-4213-6d98-f5d0-79954573930c",
      "8820f9c1-43d3-55eb-f15a-53abaac6f49e",
      "3bc38af9-4068-164e-d42f-da844a259058",
      "6d627f56-4f0e-e217-d0ff-87b4fa6c66bb",
      "636c1f83-44f7-6bc4-0b24-88a1beb66c2d",
      "968efd06-4549-65f4-9fb1-3f90d612e428",
      "23de6607-4647-bccd-8863-3da2720e4152",
      "49cea67c-4552-13c2-6b4b-8ba07761504e",
      "c4422d16-4cb1-75d5-fd18-dda0bb05f46b",
      "1969e379-40ae-b28a-6fb3-819873a24fb9",
      "851b390a-4852-66bd-8b72-4f96f0919710",
      "c9678d8c-4327-f397-b0ec-dca3c3d6fb15",
      "1d722b60-4def-659b-cb30-45970ab2e606",
      "51b545fa-4b53-65fb-1551-cc8d0554cbeb",
      "e8a3da62-4bdc-d7cb-1383-568b73b80b6b",
      "a0801408-4a88-3bc7-df50-0baaecf5040b",
      "c8931118-40b7-dfb6-c071-85be21d51b4d",
      "aae492eb-4d6d-097d-1d47-4fa31ced9751",
      "a8c10620-46a8-794e-be55-a0a13edb8d44",
      "9246f649-40b1-4077-31d1-be856acf5dd9",
      "690bcddd-4b2e-d856-7b4f-0e81f7c21133",
      "ba42fe63-457a-78ce-4499-47950a698129",
      "1d2e2fd5-4fef-61f2-a4ec-92819d16fcf2",
      "94cf6dd4-42c5-f70e-1652-dca05fa42ad6",
    ],
    幻象: [
      "28a7fd58-425c-6aa7-40d6-539d5fdac46c",
      "a7cf8684-41a0-3dd4-e30c-6f9b4b34e635",
      "49a986d0-4a07-b756-331e-babe1b2839d9",
      "82db01d1-4192-167b-9f53-78ba374c39ac",
      "524b0c42-47da-e7ca-dc04-85ba285b2e71",
      "b37b99d4-4bbe-98c6-0168-3dafda0768e9",
      "1ef0a872-48cf-2ae2-2bbb-979869970b33",
      "f7324434-4288-3bcf-aa60-a6bc11384bd4",
      "9f6e4612-433b-aea9-1683-3db7aee90848",
      "aeb0ea2e-4f50-6b34-27b0-f2a755d27f6a",
      "0ff7ff25-42cf-769a-4e6d-bd833121302d",
      "814fb822-4585-c9fe-85b4-bf8157646ae7",
      "49a65cb3-4bcb-13c1-10b1-d1a4957edeea",
      "279e0a89-4dd6-b135-cef9-8ebb1df6f2ac",
      "83108c64-4b66-87bc-b4e0-db822cd466c7",
      "284aea8b-43b6-e5fe-686f-00b58ec2b17a",
      "7b534393-40b0-38d0-1ffc-2b8b78708264",
      "c0879eb2-4e41-d7bd-80f7-dd8cb13a1e20",
      "30423af3-42f9-d0ba-dee5-0b93704b39c8",
      "b0c661cd-47e6-9857-8831-ef92b880a7b3",
      "52c3bbf6-491f-fef1-faa9-e19df06a687c",
      "40308b30-4689-9713-52fb-6f8a3534213f",
      "bbc68d13-42b8-6f2c-d5b2-e79c291677c5",
      "f9d54c11-4c6f-575b-5bed-3cb693f6f2ba",
      "a165db9b-4692-ac34-d00b-d39c5fad6f31",
      "9dc0bdd7-4d88-9360-38d6-3ea62b1daaca",
      "aa610521-4bea-bc0e-1bd4-94b67fd90db6",
      "82da0235-41c6-4a04-8296-e9baaa8f12a0",
      "04dbf8e5-44fc-73f7-0655-8ca05a47739c",
      "b8bb264c-4578-2410-8dfa-6aacfeb318b0",
      "c00e786e-4e6f-0ef7-0ce3-32ba9918ba41",
      "4c18d802-409d-ec20-f630-d3abfcaa37c7",
      "72303559-400a-6a2c-d081-2aae438fe6e8",
      "5be0b43b-4e66-ab8a-91d9-be9137e2e1c2",
    ],
    捍衛者: [
      "43361d49-4331-ee30-e356-0f925e6e17b9",
      "19ba907b-4a76-347f-ea24-38990c9ff755",
      "0e1af0d5-4dee-15db-25b5-31a7da6f1d52",
      "920d9d08-4f22-9f1e-b309-ddbbe5ecb91c",
      "c9ceffba-471d-ed6c-04a5-11b71bef3f60",
      "e16ea577-4d7f-e686-456a-54b4b1d9cba2",
      "bf6df430-4e46-5c51-df6f-c2bc3b1cb4fe",
      "f4861ffb-480d-7ddc-f1a3-45a6dfaa4229",
      "9336ab9d-445c-0872-a283-9f9b61a0098a",
      "e63eab09-47e4-25be-6c9f-57995385429d",
      "fee2c305-40d1-1cca-08b8-46bceda98eca",
      "c9f0ea7f-4bed-b10e-62d2-0394444feed1",
      "f202625e-4647-b575-07a9-3fbc39d72b10",
      "8f8f82f4-4133-82c9-50b2-3c9c67e0f9fb",
      "7bc358d8-42ad-611f-5594-37888c23abe3",
      "fa3d6a11-46c4-2caf-f7e4-10a5013c034b",
    ],
    鬥牛犬: [
      "7deb8bd7-4ff6-64d2-1f23-2fb7143a5d98",
      "544ddf0f-42c6-e410-c9db-fc8b3b2c84d4",
      "45e51d17-480a-6e28-cdd2-459d7758bea8",
      "43854c54-4263-571c-9be6-52b6d50d3190",
      "b6467a97-4f1d-539e-1ae8-b8a1dfb5fd3c",
      "4a5ad8cd-4684-a47c-b393-ce9c6760d21a",
      "89b68205-4fe5-2434-a731-e5a734bd4a7a",
      "40fd5a8c-4900-93fc-62c2-aba5c7d208d2",
      "40a024bb-451d-057e-9084-8eb35c36c125",
      "94456e3a-459f-4447-cc0d-7e9b5d01b81e",
      "40f6c0c2-4ad2-6864-a0e6-2e852615f991",
    ],
  },
  2: {
    制式手槍: [
      "90cd5d86-4516-918b-29af-07b116f63aa9",
      "fe32e4bd-420f-b4cc-de37-febb78df9e16",
      "555ccfc2-41e7-484a-be15-a19a363483c2",
      "25cb63cd-416e-ab23-2377-d18638679871",
      "96bd34e5-4ee4-701c-fb88-a38fa768e81b",
      "f2863c0f-4b80-cc7c-c888-108b222863a6",
      "eb030ddc-4c6a-9766-ed1b-e29385c529dc",
      "a18c0c4a-45a5-723d-8e01-91854d1fcb65",
      "a9df1457-46bf-0431-007d-c2a6dc98e706",
      "48e72ddc-434a-4172-38b3-f98db1e0d5f2",
      "aaa3a9af-4d9b-f711-fd7d-2ea51b80f303",
      "d4b5084d-4b05-f607-6e0b-78abd16d84e3",
      "a495652c-41a8-b15d-2be3-409f3618f6c0",
      "62f4cd94-4a8f-8d08-9255-d59776694b36",
      "f7ff0083-47b0-450d-4385-5280d35290f2",
      "d9afd29a-4f08-ad0d-f13f-549e4dc871f9",
      "b00b1cb6-48f8-26f7-85cc-0c95bc57f1ac",
      "99ae20db-4e92-6afa-f1ef-3184127e88dc",
      "4c615178-4b55-e52e-9e76-9c8b78a33e98",
      "a08831cb-467a-aafd-3c5d-f2809578a9d4",
      "674ca7c1-448c-2d7c-e968-8e90ab8b75e1",
      "d93f227b-49a9-cc1a-f068-9fa6e7b9313c",
      "c9918687-49d7-1cc3-4f7c-12a0f38296d4",
      "a394be65-42a5-3e28-e162-a2b9667b6db7",
      "ec94d7fc-4c9f-0943-395a-d9905c66a24e",
      "6164ba11-4f6e-460f-f4ae-788547c978e5",
      "77f94390-43b1-cceb-b8b5-549cea343434",
      "3b182a0c-4d12-d3a3-c1e7-36b8067dc351",
      "c7625320-4c19-f977-71fe-1a81080b1154",
      "4d350010-4d39-6639-d2a0-f493183904a9",
      "d062d82e-4669-5749-2f46-e09c9156f015",
      "1855c39c-4f9d-67a8-a5ec-edae8ee4f743",
      "35541bf2-4b81-199d-04df-cdbc8d063daf",
      "a50e26fa-4a84-a186-cb51-a6bb4216e906",
      "1aa0ea7c-4368-1672-b518-e7bd0fa57fcc",
      "2ab2293c-4e55-933f-1612-a0a2131384d9",
      "418564c8-4828-9ee8-e7b3-f58cd4984357",
      "ace1d95c-4eeb-da37-b584-2683fd3f40d7",
      "745b1f55-43eb-2599-a2c5-03b4ddc8363a",
      "215ba5b7-429e-bed3-7c97-099fc9a17bf2",
      "229041e1-461d-eca9-b671-4d97110f3f6e",
      "77c2d06b-4591-8d3e-896e-a8aec24419e6",
      "a41ebba1-4201-543a-21d0-30a9ddf78032",
      "02df003f-4d42-6f5b-52ce-1484793cf18c",
      "c9d83af0-40b6-3edb-eac0-99b35483a17a",
      "513f0d31-4212-4057-b1cb-b6b0d4d51c8f",
      "06b4ef09-4096-edfe-6240-57ab5e73387e",
      "eebc735c-4933-2278-bcbe-db83a55828b0",
      "68ee5c6c-4424-e95a-f46f-c08ec2dfeb97",
      "13f3818c-4518-cf39-51f6-8cad1dc5bfc6",
      "1590d353-4b81-4207-b79a-5493231cbee7",
      "81658642-4f78-cbb4-9e40-b8856a904bc7",
      "c7695ce7-4fc9-1c79-64b3-8c8f9e21571c",
      "11dbaafe-4ef1-bbf3-d9dc-c089b88ad875",
      "1b300b91-41bb-d468-f65f-f68ec0861bd6",
      "59e65120-4806-491a-9e88-13a26c80ee3f",
      "f35f6e13-4b7b-da38-c0de-5c91fffd584b",
    ],
    狂弒: [
      "ea65ba94-468d-39a8-5ded-98820d72d19f",
      "6f2aefab-439d-140a-4dc6-87818e2d72cd",
      "4b74b3ee-4a63-7339-a28f-8b8be010ca5a",
      "31da3ec9-4eb0-ce9f-fcbb-e09ec626e748",
      "8f254346-439f-0e98-2428-62b0c669d2ae",
      "c4547e69-47a7-dc52-d09c-94bbf87dba0a",
      "2607b2c6-45f7-e75e-94f8-58a738773d5c",
      "4efc0af7-4623-cb2e-997c-6cbf2e5ddfb4",
      "64fcb53f-465d-318e-5bf9-bfa7c39b1d67",
      "4add59eb-41f6-4184-3cb6-c89a81b6739b",
      "33797241-4eea-e89f-08e0-59ac24865f6f",
    ],
    短管: [
      "f41c7d7c-4701-1612-f375-7fbfa52b7ac7",
      "09fded5d-4add-00e7-d3c4-32b8f21c7944",
      "52539664-4c23-9a90-17d4-6aba28ee6463",
      "6e81e078-46db-2314-07da-10b7326071b1",
      "93266c2b-400f-da62-24a0-739f51d33cc1",
      "f6e05633-45d6-461e-3c04-6cb03fa644aa",
      "deb200a8-447c-0e47-5b92-e8aeb9cd5525",
      "a2950772-447a-f2db-be24-19b0e0ed736f",
    ],
    鬼魅: [
      "b0c41f3b-4fa8-3768-4436-a29fbef94a68",
      "7ee8a019-4224-4a42-2c97-1899f653162e",
      "fbf4bc7e-4350-159b-dbfd-d38f5478cec5",
      "5e1f5465-40cd-2c0f-33ab-398c5367d080",
      "2dd042e4-409e-c8ed-ec76-758529e49d99",
      "f7862f1f-4340-6e42-b1fa-0ebcdcf43456",
      "0296ff9b-4172-5a29-7d25-a7bf491b5bd4",
      "51ba009e-4af0-8255-5ada-8a89d244f24c",
      "003e0991-4370-8837-f8fc-6ab3acec2dbf",
      "604fa485-43ba-0fcf-4bad-f9a4e3b6c652",
      "a6fe7710-4edc-5e23-223d-aeaef3d17866",
      "b9f4e6ea-4db2-6939-731f-0eb93cfbbb1d",
      "ed8a1109-4e48-f077-636b-e98dd332bfcc",
      "459343eb-4c56-4f10-ff2f-fbbf07d3695d",
      "f9fab42c-46bf-1bf0-dba5-32988be03fc2",
    ],
    神射: [
      "12831559-44ee-c708-b97c-29a43938e3cd",
      "31ae0595-4ee7-57c8-f61c-2e808a6b77ed",
      "e31776ad-4b73-38df-b83d-50b2f0fc512a",
      "ade427fc-4b24-f290-c91a-b1904b787f42",
      "e63cee8f-47b4-1d3d-6eb2-5b9a59f1aa2a",
      "4276d2b5-4b1c-b636-aff0-4ea579d875d7",
      "d9f04b7a-4ca6-1083-3af4-e3810bf15440",
      "3cce8de1-4800-9372-e5eb-50a8e12a4eb7",
      "72b3bacc-48ac-85f7-ec38-5ab629654486",
      "746a022b-48a9-c51b-f379-11a3e1e2bc8e",
      "c10c6539-43a7-83d9-555d-818a7c100709",
      "9ee1d68f-4b51-8ef1-b620-efa9760b0d02",
      "353c1e5f-4258-c49a-c0d6-319ad33bffea",
      "d9daaf39-4190-a63c-c2cb-ebbc707b212e",
      "59733cf9-490b-e25b-2c6f-eca947628079",
      "72586d88-46c4-1532-7507-d6bb5c2d201c",
      "2b555f97-46bb-5949-3531-979f5bc817f0",
      "4e4ebb8d-41d0-c326-595a-1f9b257e91fa",
    ],
  },
  3: {
    刺針: [
      "4dbeebb2-4b39-8aec-b639-00bc4948d0f3",
      "5611fa1c-45bb-6c46-73cd-209031e72524",
      "3aac74c8-4398-9179-d67d-c5a40863799b",
      "0a6790a2-4ddf-56aa-26e4-44b45d050060",
      "9bc535dd-4d91-3421-2cd0-7a8fdad3478b",
      "ca2f7bc0-4270-7f76-4623-8596a5584d06",
    ],
    惡靈: [
      "5eb7515f-40a1-2f15-de03-c9a024664737",
      "fc7d7da0-4381-6f6a-1e2c-1baba60accb0",
      "737d2d1b-4b47-87a4-6045-5183580d27ec",
      "3b955119-421c-3319-50cc-1aaf06b42338",
      "eb26c879-4a83-86ad-74a5-4db9fd6b3983",
      "87220abc-49a9-7472-ae09-29a6d711bbc3",
      "4aa902b8-4418-9b72-74f5-e59464691b40",
      "bbb9a521-4460-1e77-536b-49b87d3954e0",
      "fdcb9cc0-4877-6f89-02ad-98ab330f3043",
      "291cb44a-410d-b035-4d0b-608a92c2cd91",
      "ef773863-472d-4d81-e50e-1d887cea40f4",
      "b39aed48-4bb5-089b-3f60-17aa27e45733",
      "daa73753-4b56-9d21-d73e-f3b3f4c9b1a6",
      "2e697099-42dd-4283-4249-fcbd685ecac7",
      "d1d528ae-4dcc-e693-68e2-e8a475df83a4",
      "7e45e9cd-4072-cc32-0898-d09c167cc0f9",
      "f2ef87c7-4ff5-54f8-e038-cea4e54eefdd",
      "8af83962-4e2f-f58e-588e-ef85e4882b79",
      "8a1b2c5b-4692-78ae-9d5c-cdac6a61e4f4",
      "0a0237d3-4d57-0ed2-ab65-c898a7bc755b",
      "613dd54a-4068-0fab-7670-bf8268a9b76b",
    ],
  },
  4: {
    重砲: [
      "1f962646-47ba-cce6-f138-e1a11c200b86",
      "01a984b8-4b91-ab61-60b9-1bb9ce54bd04",
      "89e2e0cf-4456-5acd-c55a-5cb3f6ff2041",
      "56042ce2-4f95-19be-7cac-5fb191683953",
      "bddfd0ba-49a1-0f4f-e32a-d5a1ed29d5fc",
      "f987be1f-4287-35a4-34a9-d6a92805e7ff",
      "4ae8e917-479e-a40e-9627-daaf44fe8ac2",
      "78776175-4040-282f-07a1-0199b1fdcf58",
      "f00520c8-4b70-7255-d23c-c0a4887656a2",
      "cdc130c2-4b12-3702-c8f6-5a8920746395",
      "22c0aaa3-4919-5a7b-90c0-2dacafdc32fe",
    ],
    判官: [
      "d8c9fee3-4e02-bc92-a235-608a556905ae",
      "7a663e1c-49e3-f66d-6e28-93a81938cf85",
      "712c7a37-4524-0b27-dfdd-4e95181dd36e",
      "b0d5c4da-4d81-4386-e841-759818ce9b6c",
      "4f44cdaa-4810-efd2-0531-5d9565a4ec39",
      "70538fc5-4f2f-8603-3175-37a45cf90a28",
      "f27be4ed-4fa5-9c3a-592a-b98bb455fd12",
      "4f45735c-4513-e9a6-48ac-35acca4c3410",
      "8c02ea4f-45ce-76ba-9ea6-198156deb9eb",
      "d9db5d2e-46a9-deea-09b6-dd9c7f7b9334",
    ],
  },
  5: {
    警長: [
      "1a255561-47e0-112c-7e23-ecbfcb35e4a3",
      "a1711423-49bd-794e-8f77-f1b9f8749242",
      "60edaaf5-41b2-1652-af0a-2dafed7bc71e",
      "6046ca8f-4100-4b1a-7e8d-5e8c6625342f",
      "43ea5cae-4535-7903-f2c9-65bac9642a99",
      "38e6f1d6-4f58-f090-1e48-99aacea8b47c",
      "b2f78500-4322-b03d-2745-a3b2208d63fe",
      "57a31c23-4742-9bef-740e-c998061d720b",
      "c5dd6298-4928-5d64-5cd0-7fa41ea89d81",
    ],
    逃犯: [
      "a519475f-4326-481c-6900-989911df4ce0",
      "d76e97cf-4a23-cafa-fe60-439a8ca7b37a",
    ],
    間諜: [
      "722a1311-43e1-7c18-ce90-acac33e9c2ad",
      "5c273d0e-47fa-bb8c-d914-728de95da30e",
      "2ad7a62f-4091-ecba-de21-d19edfb41086",
      "bf2da3c9-43aa-5a37-9958-2a805298951f",
      "d92a6ee6-4073-8b7f-e944-f1a55606a28a",
      "7c89312b-45fb-a892-4525-659ba3de4475",
      "176e6fa8-472d-9eb1-a474-11a3080c4053",
      "69657d05-4309-9779-d445-6da864067246",
      "99b56bab-42cc-fd50-8604-6995bebd011f",
      "68811d2b-4a6d-cbdc-39d3-f580aa97a297",
      "4d19c241-4350-6658-f5a6-5c99ca8e5e99",
      "10a5fe91-4efa-9d6b-44f7-47ae9660550d",
      "369c9f89-49fa-94bc-f3f8-b9b94b6395af",
      "4c053776-467f-2c6a-b609-0ba3ea371a14",
      "a7ec8cbf-499b-31cc-d58f-a2a900d7239c",
      "24c73c29-443c-2440-d6db-838086f2451a",
      "932f4704-44ec-b931-0141-a8b364c0f7b5",
      "4aa31563-44e1-0d9f-f95a-a1b9965ad762",
      "7bfab387-4e97-d815-4488-c491e3a5520c",
      "65d832e3-4b34-b4b9-fde0-9d897d5fe51b",
    ],
  },
  6: {
    戰神: [
      "642ee168-4f9e-1f5f-be72-01b3a41ac086",
      "0ddbdf8c-43b0-e2f1-94f8-d6be9d2a2110",
      "1203a0b8-4044-a7de-1751-87b30b9574e8",
      "0767c288-4ac6-4a8c-6f76-be8ca91e2991",
      "7d3c0ac4-42ce-7111-2c86-43be3d2e86a7",
      "8e0c9fac-4d40-0a04-cf4a-4bb553952164",
      "46048768-4499-72f7-820b-7cbe1d4ad844",
      "857ad950-486a-d199-7bf2-7092aaf88ae2",
      "6ab209eb-4596-c1f5-0262-22ac9f2beaa0",
      "c6c7ffc4-4892-6393-38b3-1b92837a8038",
      "8b829b66-44ad-c5d3-4e03-f5bc3c6f5d12",
    ],
    奥丁: [
      "e6b64b6e-41cb-a99f-dceb-c0bc95b93d0d",
      "030e4532-44fb-c430-cda5-a68442bcf4e5",
      "549b06bb-4704-25ce-19d5-c9b70b10de19",
      "6f60b3ce-4cbf-91ea-2ca7-59bbf1923751",
      "e96f3e84-471a-b20f-a3c8-39b3a607c9c8",
      "5123ed96-48de-aeab-eaea-2b8ea689f5a2",
      "89baf0f4-4648-69fc-f0af-2fbc69b97b80",
      "ef564ec3-497c-3038-543e-eb94bbe46437",
      "f5ce6297-4cd4-4b09-3931-5f8b20a4317d",
      "501811a4-4d60-d1fd-2775-ea92530ccbfa",
    ],
  },
};
