// pages/HomePage.js
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { fetchValorantPlayerMatch } from "./fetch/ValorantPlayerMatch";
import Detail from "./components/Detail";
import Rank from "./components/Rank";
import MatchContainer from "./components/Match";
import styles from "@scss/pages/ValorantPage/ValorantPage.module.scss";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import { refreshAccessToken } from "../../Auth";
const serverName = { ap: "亚服", na: "美服", eu: "欧服", kr: "韩服" };
export default function ValorantPage() {
  const { id } = useParams();

  const [accountInfo, setAccountInfo] = useState(null); // 存储请求到的数据
  const [loading, setLoading] = useState(true); // 表示加载状态
  const [error, setError] = useState(null); // 存储错误信息

  useEffect(() => {
    let isMounted = true; // 标记组件是否挂载
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/valorant/${id}/`
        );
        if (isMounted) {
          setAccountInfo(response.data);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
          setLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      <div className={styles["account__container"]}>
        {accountInfo ? (
          <Account accountInfo={accountInfo} />
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
}

function Account({ accountInfo }) {
  return (
    <div className={styles["account"]}>
      <div className={styles["account__info"]}>
        <AccountInfo accountInfo={accountInfo} />
        <Rank rank={accountInfo.valorantAccount.rank} />
        <Payment price={accountInfo.valorantAccount.price} />
        {/* <MatchContainer matches={matches} /> */}
        {/* <Hisrank seasons={accountInfo.valorant_hisrank} /> */}
      </div>
      <Detail
        items={accountInfo.items}
        accountInfo={accountInfo.valorantAccount}
        totalValue={accountInfo.valorantAccount.value}
        latestTier={accountInfo.valorantAccount.rank}
      />
    </div>
  );
}

function AccountInfo({ accountInfo }) {
  return (
    <div className={styles["AccountInfoData"]}>
      <AccountInfoDataInfo accountInfo={accountInfo} />
      <AccountInfoCurrency accountInfo={accountInfo} />
    </div>
  );
}

function AccountInfoDataInfo({ accountInfo }) {
  return (
    <div className={styles["AccountInfoData__Info"]}>
      <div className={styles["AccountInfoData__Avatar"]}>
        <img
          src={`/static/avatars/${accountInfo.valorantAccount.avatar}.png`}
          alt={accountInfo.valorantAccount.avatar}
          draggable="false"
        />
      </div>
      <div className={styles["AccountInfoData__TextContainer"]}>
        <div className={styles["AccountInfoData__Text"]}>
          <div className={styles["AccountInfoData__Title"]}>服务器</div>
          <div className={styles["AccountInfoData__Line"]}></div>
          <div className={styles["AccountInfoData__TextDetail"]}>
            {serverName[accountInfo.valorantAccount.server]}
          </div>
        </div>
        <div className={styles["AccountInfoData__Text"]}>
          <div className={styles["AccountInfoData__Title"]}>邮箱</div>
          <div className={styles["AccountInfoData__Line"]}></div>
          <div className={styles["AccountInfoData__TextDetail"]}>
            {accountInfo.valorantAccount.email ? "带初邮" : "无初邮"}
          </div>
        </div>
        <div className={styles["AccountInfoData__Text"]}>
          <div className={styles["AccountInfoData__Title"]}>等级</div>
          <div className={styles["AccountInfoData__Line"]}></div>
          <div className={styles["AccountInfoData__TextDetail"]}>
            {accountInfo.valorantAccount.level
              ? accountInfo.valorantAccount.level
              : "未知"}
          </div>
        </div>
        <div className={styles["AccountInfoData__Text"]}>
          <div className={styles["AccountInfoData__Title"]}>皮肤</div>
          <div className={styles["AccountInfoData__Line"]}></div>
          <div className={styles["AccountInfoData__TextDetail"]}>
            {accountInfo.items.skins.length}
          </div>
        </div>
      </div>
    </div>
  );
}

function AccountInfoCurrency({ accountInfo }) {
  return (
    <div className={styles["AccountInfoData__Currency"]}>
      <div className={styles["AccountInfoData__CurrencyType"]}>
        <img src="/static/icon/vp.webp" alt="vp" />
        <div className={styles["AccountInfoData__Text"]}>
          {accountInfo.valorantAccount.vp}
        </div>
      </div>
      <div className={styles["AccountInfoData__CurrencyType"]}>
        <img src="/static/icon/radianite.webp" alt="radianite" />
        <div className={styles["AccountInfoData__Text"]}>
          {accountInfo.valorantAccount.radianite}
        </div>
      </div>
      <div className={styles["AccountInfoData__CurrencyType"]}>
        <img src="/static/icon/kc.webp" alt="kc" />
        <div className={styles["AccountInfoData__Text"]}>
          {accountInfo.valorantAccount.kc}
        </div>
      </div>
    </div>
  );
}

function Info({
  server,
  region,
  level,
  value,
  vp,
  r,
  k,
  isPublicName,
  gameName,
}) {
  return (
    <div className={styles["account__BasicInfo"]}>
      <div className={styles["account__local"]}>
        {isPublicName && (
          <div className={`${styles["account__region"]} ${styles.username}`}>
            <div>Name: {gameName}</div>
          </div>
        )}
        <div className={styles["account__server"]}>
          <div>Server</div>
          <img
            src="/static/icon/world.png"
            alt="world"
            className={styles["account__server-server"]}
            draggable="false"
          />
          <div className={styles["uppercase"]}>{server}</div>
        </div>
      </div>
      <div className={styles["account__lv"]}>
        <div className={styles["account__level"]}>
          <div>Level</div>
          <img
            src="/static/icon/right.png"
            alt=""
            className={styles["account__level-img"]}
            draggable="false"
          />
          <div>{level}</div>
        </div>
        <div className={styles["account__value"]}>
          <div>Value</div>
          <img
            src="/static/icon/vp.webp"
            alt=""
            className={styles["account__value-img"]}
            draggable="false"
          />
          <div>{value}</div>
        </div>
      </div>
      <div className={styles["account__remain"]}>
        <div className={styles["account__remain-info"]}>
          <img src="/static/icon/vp.webp" alt="vp" draggable="false" />
          <div>{vp}</div>
        </div>
        <div className={styles["account__remain-info"]}>
          <img
            src="/static/icon/radianite.webp"
            alt="radianite"
            draggable="false"
          />
          <div>{r}</div>
        </div>
        <div className={styles["account__remain-info"]}>
          <img src="/static/icon/kc.webp" alt="kc" draggable="false" />
          <div>{k}</div>
        </div>
      </div>
    </div>
  );
}

function Payment({ price }) {
  const navigate = useNavigate();
  function handleNavigate() {
    navigate("/contact");
  }

  return (
    <div className={styles["payment"]}>
      <div className={styles["payment__deliver"]}>
        <div className={styles["payment__title"]}>
          <img
            src="/static/icon/shield.png"
            alt="shield"
            className={styles["payment__Image"]}
          />
          售后保障
        </div>
        <div className={styles["payment__text"]}>
          所有账号均和国外合作伙伴共同安全回收，开业至今无一找回，敢承诺找回包赔，让您玩的放心，玩的痛快。
        </div>
      </div>

      <div className={styles["payment__container"]}>
        <div className={styles["payment__ContainerLine"]}></div>
        <div className={styles["payment__buy"]}>
          <div className={styles["payment__price"]}>
            <div>小计</div>
            <div className={styles["payment__priceText"]}>
              ￥{price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </div>
          </div>
          <div className={styles["payment__contact"]} onClick={handleNavigate}>
            <div className={styles["payment__text"]}>联系</div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PaymentProcess() {
  return (
    <div className={styles["payment__process"]}>
      <div className={styles["payment__title"]}>交易流程</div>
      <div className={styles["payment__deliverStepContainer"]}>
        <div className={styles["payment__deliverStep"]}>
          <div className={styles["payment__circleContainer"]}>
            <div className={styles["payment__circle"]}></div>
            <div className={styles["payment__circle"]}></div>
            <div className={styles["payment__circle"]}></div>
            <div className={styles["payment__circle"]}></div>
          </div>
          <div className={styles["payment__line"]}></div>
        </div>
        <div className={styles["payment__stepText"]}>
          <div>付款</div>
          <div>发货</div>
          <div>换绑</div>
          <div>完成</div>
        </div>
      </div>
    </div>
  );
}
