import styles from "@scss/pages/ContactPage/ContactPage.module.scss";
export default function ContactPage() {
  return (
    <div className={styles["ContactPage"]}>
      <WeChat />
    </div>
  );
}

function WeChat() {
  return (
    <div className={styles["WeChat"]}>
      <img src="/static/icon/wechat.png" alt="wechat" />
      <div className={styles["WeChat__Text"]}>微信号：zee9581</div>
    </div>
  );
}
