import { useState, useEffect, useRef } from "react";
import styles from "@scss/pages/ValorantPage/ValorantPage.module.scss";
import jsonpath from "jsonpath";

export default function Detail({ items, accountInfo, totalValue, latestTier }) {
  const [select, setSelect] = useState("skins");
  const [imageSkins, setImageSkins] = useState(null);
  function handleChange(newSelect) {
    setSelect((prevSelect) => {
      return newSelect;
    });
  }

  return (
    <div className={styles["account__detail"]}>
      {/* <Bundles
        select={select}
        onChange={handleChange}
        items={items}
        imageSkins={imageSkins}
        accountInfo={accountInfo}
        totalValue={totalValue}
        latestTier={latestTier}
      /> */}
      <Items select={select} items={items} setImageSkins={setImageSkins} />
    </div>
  );
}

function Bundles({
  select,
  onChange,
  items,
  imageSkins,
  accountInfo,
  totalValue,
  latestTier,
}) {
  return (
    <div className={styles["bundles__container"]}>
      <div
        className={`
        ${styles["left-border_radius"]} 
        ${styles.border} 
        ${select === "skins" ? styles.select : styles.option}`}
        onClick={() => onChange("skins")}
      >
        Skins
      </div>
      {/* <div
        className={`
          ${styles.border} 
          ${select === "agents" ? styles.select : styles.option}`}
        onClick={() => onChange("agents")}
      >
        Agents
      </div>
      <div
        className={`
          ${styles.border} 
          ${select === "buddies" ? styles.select : styles.option}`}
        onClick={() => onChange("buddies")}
      >
        Gun Buddies
      </div>
      <div
        className={`
          ${styles.border} 
          ${select === "sprays" ? styles.select : styles.option}`}
        onClick={() => onChange("sprays")}
      >
        Sprays
      </div>
      <div
        className={`
          ${styles.border} 
          ${select === "cards" ? styles.select : styles.option}`}
        onClick={() => onChange("cards")}
      >
        Cards
      </div>
      <div
        className={`
          ${styles.border} 
          ${select === "titles" ? styles.select : styles.option}`}
        onClick={() => onChange("titles")}
      >
        Titles
      </div> */}
      {/* {accountInfo.allow_image && (
        <DownloadAccountImage
          accountInfo={accountInfo}
          totalValue={totalValue}
          imageSkins={imageSkins}
          latestTier={latestTier}
        />
      )} */}
    </div>
  );
}

function Items({ select, items, setImageSkins }) {
  const [skinsData, setSkinsData] = useState(null);
  const [agentsData, setAgentsData] = useState(null);
  const [titlesData, setTitlesData] = useState(null);
  const [skins, setSkins] = useState([]);
  const isFetchedRef = useRef(false);
  const skinsLevel = {
    "411e4a55-4e59-7757-41f0-86a53f101bb5": "ultra",
    "e046854e-406c-37f4-6607-19a9ba8426fc": "exclusive",
    "60bca009-4182-7998-dee7-b8a2558dc369": "premium",
    "0cebb8be-46d7-c12a-d306-e9907bfc5a25": "deluxe",
    "12683d76-48d7-84a3-4e09-6985794f0445": "select",
  };
  const desiredOrder = [
    "411e4a55-4e59-7757-41f0-86a53f101bb5",
    "e046854e-406c-37f4-6607-19a9ba8426fc",
    "60bca009-4182-7998-dee7-b8a2558dc369",
    "0cebb8be-46d7-c12a-d306-e9907bfc5a25",
    "12683d76-48d7-84a3-4e09-6985794f0445",
  ];
  const [oneBuddies, setOneBuddies] = useState(null);

  // 请求物品名字和等级
  useEffect(() => {
    // 去重Buddies重复的吊坠
    const uniqueBuddies = Array.from(
      new Map(items.buddies.map((item) => [item.uuid, item])).values()
    );
    setOneBuddies(uniqueBuddies);
    if (isFetchedRef.current) return;
    isFetchedRef.current = true;

    fetch("/valorantData/skins_zh-TW.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setSkinsData(data);

        // 根据uuid获取皮肤的其他信息如content和name
        const processedSkins = items.skins.map((item) => {
          let name = jsonpath.query(
            data,
            `$.data[?(@.levels[0].uuid=='${item.uuid}')]['displayName']`
          )[0];
          // 去除掉名字里的//
          name = name.replace(/\/\//g, "");

          return {
            ...item,
            uuid: item.uuid,
            name: name,
            content: jsonpath.query(
              data,
              `$.data[?(@.levels[0].uuid=='${item.uuid}')]['contentTierUuid']`
            )[0],
          };
        });

        // 排序逻辑
        const sortedSkins = [...processedSkins].sort((a, b) => {
          const indexA = desiredOrder.indexOf(a.content);
          const indexB = desiredOrder.indexOf(b.content);

          // 如果 content 不在 desiredOrder 中，放在最后
          if (indexA === -1 && indexB === -1) return 0;
          if (indexA === -1) return 1;
          if (indexB === -1) return -1;

          return indexA - indexB;
        });

        setSkins(sortedSkins);
        setImageSkins(sortedSkins);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    fetch("/valorantData/agents.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setAgentsData(data);
      });

    fetch("/valorantData/playertitles.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTitlesData(data);
      });
  }, []);

  return (
    <>
      {skinsData ? (
        <div className={styles["items__container"]}>
          {select === "skins" &&
            skins.map((skin) => (
              <Skins
                uuid={skin.uuid}
                name={skin.name}
                content={skinsLevel[skin.content]}
                total_level={skin.total_level}
                key={skin.uuid}
              />
            ))}

          {select === "agents" &&
            items.agents.map((agent) => (
              <Agents
                uuid={agent.uuid}
                name={
                  jsonpath.query(
                    agentsData,
                    `$.data[?(@.uuid == '${agent.uuid}')].displayName`
                  )[0]
                }
                key={agent.uuid}
              />
            ))}
          {select === "buddies" &&
            oneBuddies.map((buddie) => (
              <Bunddies
                uuid={buddie.uuid}
                name={buddie.name}
                key={buddie.uuid}
              />
            ))}
          {select === "sprays" &&
            items.sprays.map((spray) => (
              <Sprays uuid={spray.uuid} key={spray.uuid} />
            ))}
          {select === "cards" &&
            items.cards.map((card) => (
              <Cards uuid={card.uuid} key={card.uuid} />
            ))}
          {select === "titles" &&
            items.titles.map((title) => (
              <Titles
                name={
                  jsonpath.query(
                    titlesData,
                    `$.data[?(@.uuid == '${title.uuid}')].titleText`
                  )[0]
                }
                key={title.uuid}
              />
            ))}
        </div>
      ) : (
        <p>loading...</p>
      )}
    </>
  );
}

function Skins({ uuid, name, content }) {
  return (
    <div className={styles["skins__box"]}>
      <div className={styles["skins__box-skins"]}>
        <div className={`${styles["skin"]} ${styles[content]}`}>
          <img
            className={styles["logo"]}
            src="/static/icon/valorant.svg"
            alt="Valorant Icon"
            draggable="false"
          />
          <img
            className={styles["weapon_skin"]}
            src={`/static/skins/${uuid}.png`}
            alt={uuid}
            draggable="false"
          />
        </div>
        <div className={styles["skin_info"]}>
          <img
            src={`/static/skin_level/${content}.webp`}
            alt="deluxe"
            className={styles["fixed-image"]}
            draggable="false"
          />
          <div className={styles["text"]}>{name}</div>
        </div>
      </div>
    </div>
  );
}

function Agents({ uuid, name }) {
  return (
    <div className={styles["agents__box"]}>
      <div className={styles["agents"]}>
        <div className={styles["agent__img"]}>
          <img
            className={styles["agent__img"]}
            src={`/static/agents/${uuid}.png`}
            alt={uuid}
            draggable="false"
          />
        </div>
        <div className={styles["agent__name"]}>{name}</div>
      </div>
    </div>
  );
}

function Bunddies({ uuid }) {
  return (
    <div className={styles["buddies__box"]}>
      <div className={styles["buddie"]}>
        <div className={styles["buddie__img"]}>
          <img
            className=""
            src={`/static/buddies/${uuid}.png`}
            alt={uuid}
            draggable="false"
          />
        </div>
      </div>
    </div>
  );
}

function Sprays({ uuid }) {
  return (
    <div className={styles["sprays__box"]}>
      <div className={styles["spray"]}>
        <div className={styles["spray__img"]}>
          <img
            className=""
            src={`/static/spray/${uuid}.png`}
            alt={uuid}
            draggable="false"
          />
        </div>
      </div>
    </div>
  );
}

function Cards({ uuid }) {
  return (
    <div className={styles["cards__box"]}>
      <div className={styles["player-card"]}>
        <img
          className=""
          src={`/static/cards/${uuid}.png`}
          alt={uuid}
          draggable="false"
        />
      </div>
    </div>
  );
}

function Titles({ name }) {
  return (
    <div className={styles["titles__box"]}>
      <div className={styles["player-title"]}>{name}</div>
    </div>
  );
}

function DownloadAccountImage({
  accountInfo,
  totalValue,
  imageSkins,
  latestTier,
}) {
  const [isLoading, setIsLoading] = useState(false);

  async function handleDownloadImage() {
    setIsLoading((load) => !load);
    const data = {
      accountInfo: accountInfo,
      totalValue: totalValue,
      skins: imageSkins,
      latestTier: latestTier,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/valorantImage/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      const contentDisposition = response.headers.get("content-disposition");
      let fileName = `${Date.now()}.png`;
      if (contentDisposition) {
        const fileNameMatch = contentDisposition.match(/filename="(.+)"/);
        if (fileNameMatch.length === 2) fileName = fileNameMatch[1];
      }
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("下载图片时出错:", error);
    }
    setIsLoading((load) => !load);
  }
  return (
    <div className={styles["download__container"]}>
      <div
        className={
          isLoading
            ? `${styles["loading"]} ${styles["download__loading"]} `
            : styles["download__button"]
        }
        onClick={!isLoading ? handleDownloadImage : null}
      >
        {isLoading && <div className={styles["shimmer"]}></div>}
        <img
          className={styles["download__icon"]}
          src="/static/icon/downloads.png"
          alt="Downloads Icon"
          draggable="false"
        />
      </div>
    </div>
  );
}
