import styles from "@scss/pages/AnalyzePage/AnalyzePage.module.scss";
import { useState, useEffect } from "react";
import axios from "axios";
export default function ValorantSetting() {
  return (
    <div className={styles["ValorantSetting"]}>
      <AddAccount />
    </div>
  );
}

function AddAccount() {
  // 添加通过404登录账号
  return (
    <div className={styles["addAccount"]}>
      <div className={styles["addAccount__Title"]}>添加账号</div>
      <UploadGamermarkt />
      <UploadLogin />
    </div>
  );
}

function UploadGamermarkt() {
  // 添加GamerMarks账号
  const [code, setCode] = useState();
  const [price, setPrice] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleSubmit = () => {
    if (!code) return;
    setLoading(true);
    const postData = {
      html: code,
      price: price,
    };

    axios
      .post(
        `${process.env.REACT_APP_API_URL}/valorantUploadGamermarkt/`,
        postData
      )
      .then((response) => {
        setCode("");
        setLoading(false);
        setMessage(response.data.message);
        setTimeout(() => {
          setMessage("");
        }, 1500);
      })
      .catch((error) => {
        console.error("发生错误:", error);
        setMessage("错误");
        setLoading(false);
        setTimeout(() => {
          setMessage("");
        }, 3000);
      });
  };

  // 预加载加载动画图片
  useEffect(() => {
    const preloadImage = new Image();
    preloadImage.src = "/static/icon/loading.png";
  }, []);

  return (
    <div className={styles["addAccount__upload"]}>
      <div className={styles["addAccount__uploadTitle"]}>GamerMarkt</div>
      <div className={styles["addAccount__uploadContainer"]}>
        <input
          type="text"
          className={styles["addAccount__uploadCode"]}
          placeholder="HTML代码"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <input
          type="text"
          className={styles["addAccount__uploadPrice"]}
          placeholder="加价"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />

        {message ? (
          message === "success" ? (
            <button
              className={`${styles["addAccount__uploadButton"]} ${styles["addAccount__uploadButton-success"]}`}
              onClick={handleSubmit}
              disabled={loading}
            >
              添加成功
            </button>
          ) : (
            <button
              className={`${styles["addAccount__uploadButton"]} ${styles["addAccount__uploadButton-error"]}`}
              onClick={handleSubmit}
              disabled={loading}
            >
              已添加
            </button>
          )
        ) : loading ? (
          <button
            className={styles["addAccount__uploadButton"]}
            onClick={handleSubmit}
            disabled={loading}
          >
            <img
              src="/static/icon/loading.png"
              alt="loading"
              className={styles["addAccount__loadingImg"]}
              draggable="false"
            />
          </button>
        ) : (
          <button
            className={styles["addAccount__uploadButton"]}
            onClick={handleSubmit}
            disabled={loading}
          >
            上传
          </button>
        )}
      </div>
    </div>
  );
}

function UploadLogin() {
  const [code, setCode] = useState();
  const [price, setPrice] = useState();
  return (
    <div className={styles["addAccount__upload"]}>
      <div className={styles["addAccount__uploadTitle"]}>404URL</div>
      <div className={styles["addAccount__uploadContainer"]}>
        <input
          type="text"
          className={styles["addAccount__uploadCode"]}
          placeholder="AUTH URL"
          value={code}
          onChange={(e) => setCode(e.target.value)}
        />
        <input
          type="text"
          className={styles["addAccount__uploadPrice"]}
          placeholder="价格"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <button className={styles["addAccount__uploadButton"]}>上传</button>
      </div>
    </div>
  );
}
