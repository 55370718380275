import { useState, useEffect } from "react";
import jsonpath from "jsonpath";
import styles from "../../../scss/pages/ValorantPage/ValorantPage.module.scss";

export default function Rank({ rank }) {
  const [rankData, setRankData] = useState(null);

  useEffect(() => {
    fetch("/valorantData/competitivetiers_zh-CN.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setRankData(data);
      });
  }, []);

  return (
    <>
      {rankData ? (
        <div className={styles["account__Rank"]}>
          <img
            src={`/static/rank/${rank}.png`}
            alt="rank"
            className={styles["account__Icon"]}
            draggable="false"
          />
          <div className={styles["account__RankText"]}>
            {jsonpath.query(
              rankData,
              `$.data[4].tiers[?(@.tier==${rank})].tierName`
            )}
          </div>
        </div>
      ) : (
        <p>记载中...</p>
      )}
    </>
  );
}
