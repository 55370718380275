import styles from "@scss/pages/AnalyzePage/AnalyzePage.module.scss";
import ValorantRecycle from "./ValorantRecycle";
import ValorantSetting from "./ValorantSetting";
import Log from "./Log";

export default function Data({ activeOption, totalPlayer, totalAccount }) {
  return (
    <>
      {activeOption === "首页" && (
        <DataHomepage totalPlayer={totalPlayer} totalAccount={totalAccount} />
      )}
      {activeOption === "瓦罗兰特" && <ValorantSetting />}
      {activeOption === "日志" && <Log />}
    </>
  );
}

function DataHomepage({ totalPlayer, totalAccount }) {
  return (
    <div className={styles["Data"]}>
      <DataHomepageWebData
        totalPlayer={totalPlayer}
        totalAccount={totalAccount}
      />
      <DataHomepageWebDetailData />
    </div>
  );
}

function DataHomepageWebData({ totalPlayer, totalAccount }) {
  return (
    <div className={styles["Data__WebData"]}>
      <DataHomepageWebDataNum
        title1={"总玩家"}
        data1={totalPlayer}
        title2={"总账号"}
        data2={totalAccount}
      />
      <DataHomepageWebDataNum
        title1={"总公开账号"}
        data1={0}
        title2={"总下载图"}
        data2={0}
      />
      <DataHomepageWebDataNum
        title1={"Sell点击数"}
        data1={0}
        title2={""}
        data2={0}
      />
    </div>
  );
}

function DataHomepageWebDataNum({ title1, title2, data1, data2 }) {
  return (
    <div className={styles["Data__WebDataContainer"]}>
      <div className={styles["Data__WebDataNumContainer"]}>
        <div className={styles["Data__WebDataTitle"]}>{title1}</div>
        <div className={styles["Data__WebDataNum"]}>{data1}</div>
      </div>

      <div className={styles["Data__WebDataLine"]}></div>
      <div
        className={`${styles["Data__WebDataNumContainer"]} ${styles["Data__WebDataNumContainer--margin-top"]}`}
      >
        <div className={styles["Data__WebDataTitle"]}>{title2}</div>
        <div className={styles["Data__WebDataNum"]}>{data2}</div>
      </div>
    </div>
  );
}

function DataHomepageWebDetailData() {
  return <div className={styles["Data__WebDetailData"]}></div>;
}
