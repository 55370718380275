import AccountFilter from "./components/AccountFilter";
import Accounts from "./components/Accounts";
import styles from "@scss/pages/ValorantSelectPage/ValorantSelectPage.module.scss";
import { useState, useEffect } from "react";
import axios from "axios";
export default function ValorantSelectPage() {
  const [selectSkin, setSelectSkin] = useState([]);

  const [accountData, setAccountData] = useState(null); // 存储请求到的数据
  const [loading, setLoading] = useState(true); // 表示加载状态
  const [error, setError] = useState(null); // 存储错误信息

  useEffect(() => {
    let isMounted = true; // 标记组件是否挂载

    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/`);
        if (isMounted) {
          setAccountData(response.data);
          setLoading(false);
        }
      } catch (err) {
        if (isMounted) {
          setError(err);
          setLoading(false);
        }
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  function handleSearch({ server, rank, email }) {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/valorantSearch/`,
          {
            skins: selectSkin,
            server: server,
            rank: rank,
            email: email,
          }
        );
        if (response.data.data === "none") {
          setAccountData([]);
        } else {
          setAccountData(response.data);
          setLoading(false);
        }
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }

  const sortAccountsByPrice = (order) => {
    if (accountData) {
      const sortedData = [...accountData].sort((a, b) => {
        if (order === "asc") {
          return a.price - b.price;
        } else if (order === "desc") {
          return b.price - a.price;
        }
        return 0;
      });
      setAccountData(sortedData);
    }
  };
  const sortAccountsByDate = (order) => {
    if (accountData) {
      const sortedData = [...accountData].sort((a, b) => {
        if (order === "asc") {
          return new Date(a.created_at) - new Date(b.created_at);
        } else if (order === "desc") {
          return new Date(b.created_at) - new Date(a.created_at);
        }
        return 0;
      });
      setAccountData(sortedData);
    }
  };

  return (
    <div className={styles["ValorantSelectPage"]}>
      <div className={styles["ValorantSelect"]}>
        <AccountFilter
          handleSearch={handleSearch}
          selectSkin={selectSkin}
          setSelectSkin={setSelectSkin}
        />
        <Accounts
          accountData={accountData}
          sortAccountsByPrice={sortAccountsByPrice}
          sortAccountsByDate={sortAccountsByDate}
        />
      </div>
    </div>
  );
}
