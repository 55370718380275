import styles from "@scss/pages/ValorantSelectPage/ValorantSelectPage.module.scss";
import { useEffect, useState } from "react";
import { skinsType } from "./data/skinsMenu";

export default function AccountFilter({
  handleSearch,
  selectSkin,
  setSelectSkin,
}) {
  const [showSkinsMenu, setShowSkinsMenu] = useState(true);
  const [skinData, setSkinData] = useState(null);
  const [skinNameMap, setSkinNameMap] = useState({});

  const [selectedServer, setSelectedServer] = useState("");
  const [selectedRank, setSelectedRank] = useState("");
  const [hasInitialMail, setHasInitialMail] = useState(false); // 带初邮

  function handleShowSkinsMenu() {
    setShowSkinsMenu((proShow) => !proShow);
  }

  function handleAddSelectSkin(uuid, name) {
    setSelectSkin((prevSkins) => {
      const exists = prevSkins.some((skin) => skin.uuid === uuid);
      if (exists) {
        // 如果包含指定 uuid 的皮肤已存在，则移除它
        return prevSkins.filter((skin) => skin.uuid !== uuid);
      } else {
        // 如果不存在，则添加新的皮肤对象
        return [...prevSkins, { uuid, name }];
      }
    });
  }

  function handleSearchWithParams() {
    setShowSkinsMenu(true);
    handleSearch({
      server: selectedServer,
      rank: selectedRank,
      email: hasInitialMail,
    });
  }

  // 请求皮肤数据
  useEffect(() => {
    const fetchSkinData = async () => {
      try {
        const response = await fetch("/valorantData/skins_zh-TW.json"); // 确保路径正确
        if (!response.ok) {
          throw new Error(`HTTP error! 状态: ${response.status}`);
        }
        const data = await response.json();

        // 创建一个从皮肤 UUID 到名称的映射
        const skinNameMap = {};
        data.data.forEach((skin) => {
          if (skin.levels && skin.levels.length > 0) {
            const uuid = skin.levels[0].uuid;
            const name = skin.displayName;
            skinNameMap[uuid] = name;
          }
        });

        setSkinData(data);
        setSkinNameMap(skinNameMap);
      } catch (error) {
        console.error("Error fetching skins data:", error);
      }
    };

    fetchSkinData();
  }, []);

  return (
    <div className={styles["AccountFilter"]}>
      {skinData && (
        <div className={styles["SkinsSearchContainer"]}>
          <SkinsSearch
            handleShowSkinsMenu={handleShowSkinsMenu}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          />
          <SkinsMenu
            skinData={skinData}
            skinNameMap={skinNameMap}
            showSkinsMenu={showSkinsMenu}
            handleAddSelectSkin={handleAddSelectSkin}
            selectSkin={selectSkin}
          />
        </div>
      )}
      <select
        className={styles["AccountFilter__Dropdown"]}
        onChange={(e) => setSelectedServer(e.target.value)}
      >
        <option value="" disabled selected>
          服务器
        </option>
        <option value="">全部</option>
        <option value="na">美服</option>
        <option value="eu">欧服</option>
        <option value="ap">亚服</option>
        <option value="kr">韩服</option>
      </select>
      <select
        className={styles["AccountFilter__Dropdown"]}
        onChange={(e) => setSelectedRank(e.target.value)}
      >
        <option value="" disabled selected>
          最高段位
        </option>
        <option value="">全部</option>
        <option value="0">未定段</option>
        <option value="5">黑铁</option>
        <option value="8">青铜</option>
        <option value="11">白银</option>
        <option value="14">黄金</option>
        <option value="17">铂金</option>
        <option value="20">钻石</option>
        <option value="23">超凡</option>
        <option value="26">神话</option>
        <option value="27">福能</option>
      </select>
      <div className={styles["AccountFilter__ChooseContainer"]}>
        <div className={styles["AccountFilter__Choose"]}>
          <input
            type="checkbox"
            id="option1"
            name="multiSelect"
            value="option1"
            onChange={(e) => setHasInitialMail(e.target.checked)}
          />
          <label htmlFor="option1">带初邮</label>
        </div>
      </div>
      <div
        className={styles["AccountFilter__Search"]}
        onClick={handleSearchWithParams}
      >
        搜索
      </div>
    </div>
  );
}

function SkinsSearch({ handleShowSkinsMenu, selectSkin, handleAddSelectSkin }) {
  return (
    <div className={styles["AccountFilter__SkinSearch"]}>
      <div className={styles["AccountFilter__SkinSelectContainer"]}>
        {selectSkin.length !== 0 ? (
          selectSkin.map((value) => (
            <SkinSelect
              key={value.uuid}
              value={value}
              handleAddSelectSkin={handleAddSelectSkin}
            />
          ))
        ) : (
          <div className={styles["AccountFilter__Placeholder"]}>皮肤</div>
        )}
      </div>
      <div
        className={styles["AccountFilter__SkinMenu"]}
        onClick={handleShowSkinsMenu}
      >
        <div className={styles["AccountFilter__SkinMenuLine"]}></div>
        <div className={styles["AccountFilter__SkinMenuLine"]}></div>
        <div className={styles["AccountFilter__SkinMenuLine"]}></div>
      </div>
    </div>
  );
}

function SkinSelect({ value, handleAddSelectSkin }) {
  return (
    <div className={styles["AccountFilter__SkinSelect"]}>
      <div className={styles["AccountFilter__SkinName"]}>{value.name}</div>
      <span
        className={styles["AccountFilter__CloseX"]}
        onClick={() => handleAddSelectSkin(value.uuid, value.name)}
      >
        x
      </span>
    </div>
  );
}

function SkinsMenu({
  skinData,
  skinNameMap,
  showSkinsMenu,
  selectSkin,
  handleAddSelectSkin,
}) {
  return (
    <div
      className={`${showSkinsMenu && styles["none"]} ${styles["SkinsMenu"]}`}
    >
      {Object.entries(skinsType[0]).map(([key, value]) => (
        <SkinList
          key={key}
          skins={value}
          skinNameMap={skinNameMap}
          selectSkin={selectSkin}
          handleAddSelectSkin={handleAddSelectSkin}
        >
          {key}
        </SkinList>
      ))}
      {/* 以下重复的部分可以根据实际需要调整 */}
      <div className={styles["SkinsMenu__Class"]}>
        <div className={styles["SkinsMenu__ClassTitle"]}>步枪</div>
        {Object.entries(skinsType[1]).map(([key, value]) => (
          <SkinList
            key={key}
            skins={value}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          >
            {key}
          </SkinList>
        ))}
      </div>
      <div className={styles["SkinsMenu__Class"]}>
        <div className={styles["SkinsMenu__ClassTitle"]}>隨身武器</div>
        {Object.entries(skinsType[2]).map(([key, value]) => (
          <SkinList
            key={key}
            skins={value}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          >
            {key}
          </SkinList>
        ))}
      </div>
      <div className={styles["SkinsMenu__Class"]}>
        <div className={styles["SkinsMenu__ClassTitle"]}>衝鋒槍</div>
        {Object.entries(skinsType[3]).map(([key, value]) => (
          <SkinList
            key={key}
            skins={value}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          >
            {key}
          </SkinList>
        ))}
      </div>
      <div className={styles["SkinsMenu__Class"]}>
        <div className={styles["SkinsMenu__ClassTitle"]}>霰彈槍</div>
        {Object.entries(skinsType[4]).map(([key, value]) => (
          <SkinList
            key={key}
            skins={value}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          >
            {key}
          </SkinList>
        ))}
      </div>
      <div className={styles["SkinsMenu__Class"]}>
        <div className={styles["SkinsMenu__ClassTitle"]}>狙擊槍</div>
        {Object.entries(skinsType[5]).map(([key, value]) => (
          <SkinList
            key={key}
            skins={value}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          >
            {key}
          </SkinList>
        ))}
      </div>
      <div className={styles["SkinsMenu__Class"]}>
        <div className={styles["SkinsMenu__ClassTitle"]}>機關槍</div>
        {Object.entries(skinsType[6]).map(([key, value]) => (
          <SkinList
            key={key}
            skins={value}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          >
            {key}
          </SkinList>
        ))}
      </div>
    </div>
  );
}

function SkinList({
  skins,
  skinNameMap,
  children,
  selectSkin,
  handleAddSelectSkin,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen((open) => !open);
  }

  return (
    <div className={styles["SkinList"]} draggable="false">
      <div className={styles["SkinList__Title"]} onClick={handleOpen}>
        {children}
      </div>
      <div
        className={`${!isOpen && styles["none"]} ${styles["SkinList__Option"]}`}
      >
        {skins.map((skin) => (
          <SkinOption
            key={skin}
            skin={skin}
            skinNameMap={skinNameMap}
            selectSkin={selectSkin}
            handleAddSelectSkin={handleAddSelectSkin}
          />
        ))}
      </div>
    </div>
  );
}

function SkinOption({ skin, skinNameMap, selectSkin, handleAddSelectSkin }) {
  const name = skinNameMap[skin] || "未知皮肤";

  const isSelected = selectSkin.some(
    (selectedSkin) => selectedSkin.uuid === skin
  );

  return (
    <div
      className={`${styles["SkinOption"]} ${
        isSelected ? styles["SkinOption-select"] : styles["SkinOption-hover"]
      }`}
      onClick={() => handleAddSelectSkin(skin, name)}
    >
      <div className={styles["SkinOption__Container"]}>
        <div className={styles["SkinOption__ImgContainer"]}>
          <img
            src={`/static/skins/${skin}.png`}
            alt={skin}
            className={styles["SkinOption__Img"]}
            draggable="false"
          />
        </div>
        <div className={styles["SkinOption__Name"]}>{name}</div>
      </div>
      <div className={styles["SkinOption__Line"]}></div>
    </div>
  );
}
