// src/components/GA4Tracking.js
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const GA4Tracking = () => {
  const location = useLocation();

  useEffect(() => {
    // 发送页面浏览事件
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
      title: document.title,
    });
  }, [location]);

  return null;
};

export default GA4Tracking;
