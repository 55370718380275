import { Routes, Route } from "react-router-dom";
import ValorantSelectPage from "./pages/ValorantSelectPage/ValorantSelectPage";
import ValorantPage from "./pages/ValorantPage/ValorantPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import AnalyzePage from "./pages/AnalyzePage/AnalyzePage";
import ReactGA from "react-ga4";
import "@scss/root.scss";
import GA4Tracking from "./components/GA4Tracking";
// 使用你的测量 ID 初始化
ReactGA.initialize("G-KPQ69VSDVZ");

function App() {
  return (
    <>
      <GA4Tracking />
      <Routes>
        <Route path="/" element={<ValorantSelectPage />} />
        <Route path="/valorant/:id" element={<ValorantPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/analyze" element={<AnalyzePage />} />
      </Routes>
    </>
  );
}

export default App;
