import { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styles from "@scss/pages/AnalyzePage/AnalyzePage.module.scss";
import Data from "./components/Data";
import { Navigate, useNavigate } from "react-router-dom";
export default function AnalyzePage() {
  const [me, setMe] = useState(false);
  const [activeOption, setActiveOption] = useState("首页");
  const [totalPlayer, settotalPlayer] = useState(null);
  const [totalAccount, setTotalAccount] = useState(null);
  const navigate = useNavigate();
  // 获取网站基本数据

  return (
    <>
      <div className={styles["AnalyzePageContainer"]}>
        <LeftBar
          activeOption={activeOption}
          setActiveOption={setActiveOption}
        />
        <Data
          activeOption={activeOption}
          totalPlayer={totalPlayer}
          totalAccount={totalAccount}
        />
      </div>
    </>
  );
}

function LeftBar({ activeOption, setActiveOption }) {
  return (
    <div className={styles["LeftBar"]}>
      <Logo />
      <LeftBarOptions
        activeOption={activeOption}
        setActiveOption={setActiveOption}
      />
    </div>
  );
}

function Logo() {
  const navigate = useNavigate();

  function redirectProfile() {
    navigate("/profile");
  }
  return (
    <div className={styles["LeftBar__logo"]} onClick={redirectProfile}>
      <img
        src="/static/icon/logo.png"
        alt="Itemer Logo"
        className={styles["LeftBar__logoImg"]}
      />
      <h1 className={styles["LeftBar__logoFont"]}>Itemer</h1>
    </div>
  );
}

function LeftBarOptions({ activeOption, setActiveOption }) {
  const handleOptionClick = (name) => {
    setActiveOption(name);
    // 如果需要导航，可以在这里添加路由逻辑
  };

  return (
    <div className={styles["LeftBar__Options"]}>
      <div className={styles["LeftBar__OptionsContainer"]}>
        <Option
          icon={"world"}
          name={"首页"}
          isActive={activeOption === "首页"}
          onClick={handleOptionClick}
        />
        <Option
          icon={"valorant"}
          name={"瓦罗兰特"}
          isActive={activeOption === "瓦罗兰特"}
          onClick={handleOptionClick}
        />
        <Option
          icon={"boxes"}
          name={"回收"}
          isActive={activeOption === "回收"}
          onClick={handleOptionClick}
        />
      </div>
    </div>
  );
}

function Option({ icon, name, isActive, onClick }) {
  const handleClick = () => {
    onClick(name);
  };

  return (
    <div
      className={`${styles["LeftBar__Option"]} ${
        isActive
          ? styles["LeftBar__Option--active"]
          : styles["LeftBar__Option--hover"]
      }`}
      onClick={handleClick}
    >
      <img
        className={styles["LeftBar__OptionIcon"]}
        src={`/static/icon/${icon}.png`}
        alt={icon}
        draggable="false"
      />
      <p className={styles["LeftBar__OptionText"]}>{name}</p>
    </div>
  );
}
